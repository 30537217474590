.react-select__control {
    border: 1px solid rgba(101,109,119,.24) !important;
    min-height: 35px !important;
    border-radius: 3px !important;
}

.react-select__control--is-focused {
    color: inherit !important;
    background-color: #fff !important;
    border-color: #90b5e2 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgb(32 107 196 / 25%) !important;  
}

.react-select__indicator {
    padding: 6px !important;
}

.react-select__placeholder {
    color:#a7abb1 !important;
}

    