/*Sucesso*/
.Toastify__toast--success { 
    /* color: #155724 !important; */
    /* background-color: #2c3955 !important; */
    /* border: #c3e6cb solid 1px !important; */
}
.Toastify__toast--success .Toastify__close-button {
    /* color: #155724 !important; */
}

.Toastify__toast--success .Toastify__progress-bar {
    /* background-color: #155724 !important; */
}

/*Erro*/
.Toastify__toast--error {
    color: #721c24 !important;
    background-color: #f8d7da !important;
    border: #f5c6cb solid 1px !important;
}
.Toastify__toast--error .Toastify__close-button {
    color: #721c24 !important;
}
.Toastify__toast--error .Toastify__progress-bar {
    background-color: #721c24 !important;
}