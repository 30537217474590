
:root {
    --toastify-color-success: #2c3955 !important;
    --toastify-text-color-success: white !important;
    --toastify-toast-background: #2c3955 !important;

}
.Toastify__toast-container {
    /* background-color: white !important; */
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    /* background-color: white !important; */
}